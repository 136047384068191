import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { ModalModule } from './modal.module';

@Injectable({
  providedIn: ModalModule,
})
export class ModalService {
  private config = new OverlayConfig({});
  private overlayRef: OverlayRef;
  private portal: ComponentPortal<any>;
  private componentRef: ComponentRef<any>;

  constructor(private overlay: Overlay) {}

  public open(component: ComponentType<any>, inputs = {}) {
    if (this.overlayRef) {
      throw new Error('Modal is already open');
    }

    this.overlayRef = this.overlay.create(this.config);
    this.portal = new ComponentPortal(component);
    this.componentRef = this.overlayRef.attach(this.portal);

    /**
     * Assign inputs
     */
    const componentInstance = this.componentRef.instance;

    Object.keys(inputs).forEach(
      (key) => (componentInstance[key] = inputs[key])
    );
  }

  public close() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
      this.componentRef = undefined;
    }
  }
}
