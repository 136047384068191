import { Injectable } from '@angular/core';
import {
  completeCallState,
  errorCallState,
  initialCallState,
  loadingCallState,
} from '@miks-it-accounts/models';
import {
  StripeProduct,
  StripeService as StripeAPI,
  Subscription,
  SubscriptionsService as SubscriptionsAPI,
} from '@miks-it/bff-api-angular-sdk';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { GlobalConfig } from './global-config.service';
import { StripeService } from './stripe.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {
  // Available subscriptions data
  public availablePlans$ = new BehaviorSubject<StripeProduct[]>(null);
  public availablePlansLoading$ = new BehaviorSubject(initialCallState());

  // Current user subscription data
  public userSubscription$ = new BehaviorSubject<Subscription>(null);
  public userSubscripionLoading$ = new BehaviorSubject(initialCallState());
  public userSubscripionDeleting$ = new BehaviorSubject(initialCallState());

  public initialDataLoadError$ = combineLatest([
    this.userSubscripionLoading$,
    this.availablePlansLoading$,
  ]).pipe(
    map(
      ([{ error: subscriptionLoadError }, { error: plansLoadError }]) =>
        subscriptionLoadError || plansLoadError
    )
  );

  constructor(
    private stripe: StripeService,
    private auth: AuthService,
    private subscriptionsAPI: SubscriptionsAPI,
    private stripeAPI: StripeAPI,
    private config: GlobalConfig
  ) {}

  public async loadAvailablePlans(
    opts: { force?: boolean } = {}
  ): Promise<StripeProduct[]> {
    await this.configureApi();

    if (!opts.force) {
      if (this.availablePlansLoading$.getValue().complete) {
        this.availablePlansLoading$.next(completeCallState());
        return this.availablePlans$.getValue();
      }
    }

    this.availablePlansLoading$.next(loadingCallState());

    return this.stripeAPI
      .stripeAvailableSubscriptionsGet()
      .pipe(
        take(1),
        map((response) => {
          this.availablePlans$.next(response.products);
          this.availablePlansLoading$.next(completeCallState());
          return response.products;
        }),
        catchError((err) => {
          this.availablePlansLoading$.next(errorCallState(err));
          throw err;
        })
      )
      .toPromise();
  }

  public async createCheckoutSession(priceId: string): Promise<void> {
    await this.configureApi();

    await this.stripeAPI
      .stripeCreateCheckoutSessionPriceIdPost(priceId)
      .pipe(
        take(1),
        map((response) => {
          this.stripe.instance.redirectToCheckout({
            sessionId: response.session_id,
          });

          return true;
        })
      )
      .toPromise();
  }

  public async loadUserSubscription(
    opts: { force?: boolean } = {}
  ): Promise<Subscription> {
    await this.configureApi();

    if (!opts.force) {
      const subscriptionValue = this.userSubscription$.getValue();

      if (
        subscriptionValue &&
        this.userSubscripionLoading$.getValue().complete
      ) {
        this.userSubscripionLoading$.next(completeCallState());
        return subscriptionValue;
      }
    }

    this.userSubscripionLoading$.next(loadingCallState());

    return this.subscriptionsAPI
      .meSubscriptionGet()
      .pipe(
        take(1),
        map((response) => {
          this.userSubscription$.next(response.subscription);
          this.userSubscripionLoading$.next(completeCallState());
          return response.subscription;
        }),
        catchError((err) => {
          this.userSubscription$.next(null);

          if (err?.status === 404) {
            this.userSubscripionLoading$.next(completeCallState());
            return of(null);
          } else {
            this.userSubscripionLoading$.next(errorCallState(err));
            throw err;
          }
        })
      )
      .toPromise();
  }

  public async updateUserSubscription(): Promise<Subscription> {
    await this.configureApi();

    this.userSubscripionLoading$.next(loadingCallState());

    return this.subscriptionsAPI
      .meSubscriptionPut()
      .pipe(
        take(1),
        map((response) => {
          this.userSubscription$.next(response.subscription);
          this.userSubscripionLoading$.next(completeCallState());
          return response.subscription;
        }),
        catchError((err) => {
          this.userSubscripionLoading$.next(errorCallState(err));
          throw err;
        })
      )
      .toPromise();
  }

  public async deleteUserSubscription(): Promise<void> {
    await this.configureApi();

    this.userSubscripionDeleting$.next(loadingCallState());

    return this.subscriptionsAPI
      .meSubscriptionDelete()
      .pipe(
        take(1),
        map((_response) => {
          this.userSubscription$.next(null);
          this.userSubscripionDeleting$.next(completeCallState());
        }),
        catchError((err) => {
          this.userSubscripionDeleting$.next(errorCallState(err));
          throw err;
        })
      )
      .toPromise();
  }

  public async openCustomerPortal(): Promise<void> {
    await this.configureApi();

    return this.stripeAPI
      .stripeCreatePortalSessionPost()
      .pipe(
        take(1),
        map((response) => {
          window.location.href = response.url;
        })
      )
      .toPromise();
  }

  private async configureApi() {
    const { vaultToken, keystoreToken } = await this.auth.getTokens();

    const credentials = {
      keystoreAccessToken: keystoreToken,
      vaultAccessToken: vaultToken,
      meecoDelegationId: null,
      subscriptionKey: this.config.get('subscriptionKey'),
    };

    this.subscriptionsAPI.configuration.basePath = this.config.get(
      'bffApiBasePath'
    );
    this.subscriptionsAPI.configuration.credentials = credentials;
    this.stripeAPI.configuration.basePath = this.config.get('bffApiBasePath');
    this.stripeAPI.configuration.credentials = credentials;
  }
}
