import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SnackbarComponent } from './snackbar.component';

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [SnackbarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SnackbarModule {}
