<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-card px-4">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
      <button
        class="button p-0 is-ghost has-text-black"
        aria-label="close"
        (click)="close(cancel)"
      >
        <ion-icon class="is-size-3" name="close-outline"></ion-icon>
      </button>
    </header>
    <section class="modal-card-body">
      <p class="content has-text-centered">
        {{ message }}
      </p>
    </section>
    <footer class="modal-card-foot p-4">
      <button class="button is-primary" (click)="close(confirm)">
        {{ 'confirm' | translate }}
      </button>
      <button class="button is-primary is-light" (click)="close(cancel)">
        {{ 'cancel' | translate }}
      </button>
    </footer>
  </div>
</div>
