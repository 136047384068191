import { Component, Input } from '@angular/core';
import { SnackbarType } from './snackbar-types';

@Component({
  selector: 'mks-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  public TYPES = SnackbarType;

  @Input() type: SnackbarType;
  @Input() message: string;
  @Input() close: () => void = () => {};
}
