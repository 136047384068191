import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { asyncScheduler, Subscription, timer } from 'rxjs';
import { SnackbarType } from './snackbar-types';
import { SnackbarComponent } from './snackbar.component';
import { SnackbarModule } from './snackbar.module';

@Injectable({
  providedIn: SnackbarModule,
})
export class SnackbarService {
  // for testing purposes
  public scheduler = asyncScheduler;

  private defaultDuration = 4000;

  private overlayRef: OverlayRef;
  private portal: ComponentPortal<SnackbarComponent>;
  private componentRef: ComponentRef<SnackbarComponent>;
  private timerSub: Subscription;

  public async show(
    {
      message,
      type,
      duration,
    }: {
      message: string;
      type?: SnackbarType;
      duration?: number;
    } = { message: '' }
  ) {
    this._dismissCurrent();
    this._initializePortal();
    this.overlayRef = this.overlay.create({});
    this.portal = new ComponentPortal(SnackbarComponent);
    this.componentRef = this.overlayRef.attach(this.portal);
    const component = this.componentRef.instance;

    component.message = message;
    component.type = type;
    component.close = () => this._dismissCurrent();

    this.timerSub = timer(
      duration || this.defaultDuration,
      this.scheduler
    ).subscribe(() => {
      component.close();
    });
  }

  constructor(private overlay: Overlay) {}

  private _initializePortal() {
    if (!this.portal) {
      this.portal = new ComponentPortal(SnackbarComponent);
    }
  }

  private _dismissCurrent() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
      this.componentRef = undefined;
    }
    if (this.timerSub) {
      this.timerSub.unsubscribe();
      this.timerSub = undefined;
    }
  }
}
