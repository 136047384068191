import { Inject, Injectable } from '@angular/core';
import {
  AccountInfo,
  AuthorizationUrlRequest,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  AuthData,
  CallState,
  completeCallState,
  errorCallState,
  initialCallState,
  loadingCallState,
} from '@miks-it-accounts/models';
import { AppLoaderService } from '@miks-it-accounts/shared';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { GlobalConfig } from './global-config.service';
import { MSAL_BROWSER_TOKEN } from './msal-providers';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public authData$ = new BehaviorSubject<AuthData>(null);
  public loading$ = new BehaviorSubject<CallState>(initialCallState());

  constructor(
    @Inject(MSAL_BROWSER_TOKEN) private msalBrowser: PublicClientApplication,
    private appLoader: AppLoaderService,
    private config: GlobalConfig
  ) {
    this.loading$.next(loadingCallState());

    // Register Callbacks for Redirect flow
    msalBrowser
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (tokenResponse !== null) {
          this.authData$.next({
            account: tokenResponse.account,
            tokens: {
              ...this.extarctUserIdAndEmail(tokenResponse.accessToken),
              vaultToken: tokenResponse.accessToken,
              keystoreToken: tokenResponse.accessToken,
            },
          });
          this.loading$.next(completeCallState());
        } else {
          this.refreshTokens();
        }
      })
      .catch((err) => {
        this.loading$.next(errorCallState(err));
        this.appLoader.showError();
        throw err;
      });
  }

  public async getTokens() {
    return this.authData$
      .pipe(
        filter((data) => !!data),
        take(1),
        map((data) => ({
          vaultToken: data.tokens.vaultToken,
          keystoreToken: data.tokens.keystoreToken,
        }))
      )
      .toPromise();
  }

  /**
   * Gets existing session or opens the B2C Login Flow
   */
  public async login() {
    await this.msalLoginViaBrowserRedirect();
  }

  /**
   * Log the user out of the B2C system
   */
  public async logout() {
    return this.msalBrowser.logoutRedirect();
  }

  public async refreshTokens(): Promise<void> {
    try {
      await this.refreshTokensViaBrowser();
    } catch (err) {
      if (err?.message === 'No accounts found') {
        console.log(err);
        // These errors typically result in the user being thrown to the logout screen
        this.loading$.next(completeCallState());
      } else {
        // Some other error - can possibly show a "retry" screen
        this.loading$.next(errorCallState(err));
        this.appLoader.showError();
        throw err;
      }
    }
  }

  private async refreshTokensViaBrowser(): Promise<void> {
    const account = this.getAccount();
    const authData = await this.getTokenWeb(account);
    this.authData$.next(authData);
    this.loading$.next(completeCallState());
  }

  /**
   * Will try to use a cached account if it exists (refreshing the tokens if required)
   * otherwise will show the login redirect or password reset flow.
   */
  private async msalLoginViaBrowserRedirect(): Promise<void> {
    await this.msalBrowser.loginRedirect(<AuthorizationUrlRequest>{
      scopes: this.config.get('msalConfig').scopes,
      domainHint: this.config.get('msalConfig').loginDomainHint,
      prompt: 'login', // Always show the login view (avoids logging in to previous account)
    });
  }

  /**
   * Get a json web token for the given scopes in the web platform
   */
  private getTokenWeb(account: AccountInfo) {
    return this.msalBrowser
      .acquireTokenSilent({
        scopes: this.config.get('msalConfig').scopes,
        account,
        forceRefresh: false,
        extraQueryParameters: {
          loginHint: this.config.get('msalConfig').loginDomainHint,
        },
      })
      .then((tokenResponse) => ({
        account: tokenResponse.account,
        tokens: {
          ...this.extarctUserIdAndEmail(tokenResponse.accessToken),
          vaultToken: tokenResponse.accessToken,
          keystoreToken: tokenResponse.accessToken,
        },
      }))
      .catch((err) => {
        /**
         * It probably might any error, but when refresh token expires call fails too, so user needs to re-authenticate
         */
        if (err?.errorCode === 'silent_sso_error') {
          this.login();
        }
        return null;
      });
  }

  private getAccount() {
    const allAccounts = this.msalBrowser.getAllAccounts();

    if (allAccounts?.length > 0) {
      return allAccounts[0];
    } else {
      throw new Error('No accounts found');
    }
  }

  private extarctUserIdAndEmail(
    jwt: string
  ): { userId: string; email: string } {
    const { sub, idp_access_token } = this.getJWTPayload(jwt);
    const { emails } = this.getJWTPayload(idp_access_token);

    return {
      userId: sub,
      email: emails[0],
    };
  }

  private getJWTPayload(jwt: string) {
    return JSON.parse(atob(jwt.split('.')[1]));
  }
}
