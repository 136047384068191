import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@miks-it-accounts/services';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppLoaderService } from './shared';

enum BG_COLOR_CLASSES {
  'purple' = 'purple-bg',
  'white' = 'white-bg',
}

@Component({
  selector: 'mks-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public isLoggedIn$: Observable<boolean>;

  public isMobileNavOpen = false;

  private routerEvents: Subscription;
  private translateSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document,
    private authService: AuthService,
    private router: Router,
    private appLoader: AppLoaderService,
    private translate: TranslateService
  ) {
    this.isLoggedIn$ = authService.authData$.pipe(map((data) => !!data));

    this.appLoader.enable();

    this.translateSubscription = this.translate
      .use(this.translate.getBrowserLang())
      .subscribe({
        error: () => {
          console.log(
            `Translations not found "${this.translate.getBrowserLang()}"`
          );
        },
      });
  }

  ngOnInit(): void {
    this.updateBgColorBasedOnRoute();
  }

  ngOnDestroy(): void {
    this.routerEvents?.unsubscribe();
    this.translateSubscription?.unsubscribe();
  }

  public logout() {
    this.authService.logout();
  }

  public toggleMobileNav() {
    this.isMobileNavOpen = !this.isMobileNavOpen;
  }

  public closeNavBar() {
    this.isMobileNavOpen = false;
  }

  private updateBgColorBasedOnRoute() {
    this.routerEvents = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        /**
         * Preload data logic is handled in guards
         * Disable loader once navigated to a new page
         */
        this.appLoader.disable();

        this.clearBgColorClasses();

        const colorClass = event.url.includes('/login')
          ? BG_COLOR_CLASSES.purple
          : BG_COLOR_CLASSES.white;

        this.document.body.classList.add(colorClass);
      });
  }

  private clearBgColorClasses() {
    Object.values(BG_COLOR_CLASSES).forEach((className) =>
      this.document.body.classList.remove(className)
    );
  }
}
