/**
 * Miksit
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface Metric { 
    name: Metric.NameEnum;
    /**
     * time related metrics expect value to be in milliseconds
     */
    value: number | null;
    /**
     * size should be provided in bytes
     */
    size: number | null;
    app: Metric.AppEnum;
    /**
     * must comply with MAJOR.MINOR.PATCH format
     */
    app_version: string;
    platform: Metric.PlatformEnum;
    /**
     * must comply with MAJOR.MINOR.PATCH format
     */
    platform_version: string;
    model: string | null;
    /**
     * Preferably country code in captitals
     */
    country: string | null;
}
export namespace Metric {
    export type NameEnum = 'app.attachment.chunk_download_and_decrypt_time' | 'app.attachment.chunk_download_time' | 'app.attachment.chunk_decrypt_time' | 'app.attachment.chunk_encrypt_and_upload_time' | 'app.attachment.chunk_encrypt_time' | 'app.attachment.chunk_upload_time' | 'app.video_buffering';
    export const NameEnum = {
        AttachmentChunkDownloadAndDecryptTime: 'app.attachment.chunk_download_and_decrypt_time' as NameEnum,
        AttachmentChunkDownloadTime: 'app.attachment.chunk_download_time' as NameEnum,
        AttachmentChunkDecryptTime: 'app.attachment.chunk_decrypt_time' as NameEnum,
        AttachmentChunkEncryptAndUploadTime: 'app.attachment.chunk_encrypt_and_upload_time' as NameEnum,
        AttachmentChunkEncryptTime: 'app.attachment.chunk_encrypt_time' as NameEnum,
        AttachmentChunkUploadTime: 'app.attachment.chunk_upload_time' as NameEnum,
        VideoBuffering: 'app.video_buffering' as NameEnum
    };
    export type AppEnum = 'minis' | 'maxis';
    export const AppEnum = {
        Minis: 'minis' as AppEnum,
        Maxis: 'maxis' as AppEnum
    };
    export type PlatformEnum = 'android' | 'ios';
    export const PlatformEnum = {
        Android: 'android' as PlatformEnum,
        Ios: 'ios' as PlatformEnum
    };
}

