import { Component, Inject, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WINDOW_TOKEN } from '../../global-tokens';
import { AppLoaderService } from '../app-loader.service';

@Component({
  selector: 'mks-app-error',
  templateUrl: './app-error.component.html',
  styleUrls: ['./app-error.component.scss'],
})
export class AppErrorComponent {
  @Input() buttonText: string;
  @Input() messageText: string;

  public isHidden$: Observable<boolean>;

  constructor(
    private appLoader: AppLoaderService,
    @Inject(WINDOW_TOKEN) private window: Window
  ) {
    this.isHidden$ = this.appLoader.error$.pipe(map((error) => !error));
  }

  retry() {
    this.window.location.reload();
  }
}
