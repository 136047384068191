import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  HasNoSubscriptionGuard,
  HasNoSubscriptionOrCanceledGuard,
  HasSubscriptionGuard,
  LoggedInGuard,
} from './guards';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'checkout',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  {
    path: 'setup',
    canActivate: [HasNoSubscriptionGuard],
    loadChildren: () =>
      import('./setup-subscription/setup-subscription.module').then(
        (m) => m.SetupSubscriptionModule
      ),
  },
  {
    path: 'options',
    canActivate: [HasNoSubscriptionOrCanceledGuard],
    loadChildren: () =>
      import('./subscription-options/subscription-options.module').then(
        (m) => m.SubscriptionOptionsModule
      ),
  },
  {
    path: 'join',
    canActivate: [HasNoSubscriptionGuard],
    loadChildren: () =>
      import('./join-subscription/join-subscription.module').then(
        (m) => m.JoinSubscriptionModule
      ),
  },
  {
    path: '',
    canActivate: [HasSubscriptionGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
