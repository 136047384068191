import { Component, Input, OnInit } from '@angular/core';

export enum DialogResult {
  Confirm,
  Cancel,
}
@Component({
  selector: 'mks-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() close: (result: DialogResult) => void;
  @Input() message = '';
  @Input() title = '';

  public confirm = DialogResult.Confirm;
  public cancel = DialogResult.Cancel;

  constructor() {}

  ngOnInit(): void {}
}
