import { HttpErrorResponse } from '@angular/common/http';

export interface CallState {
  loading: boolean;
  // We don't use actual `Error` objects as they are not serializable
  // (and not particularly useful)
  error: {
    message?: string;
    stack?: string;
    statusText?: string;
    status?: number;
  };
  complete: boolean;
}

export const completeCallState = () => ({
  loading: false,
  complete: true,
  error: undefined,
});

export const errorCallState = (error: Error | HttpErrorResponse) => {
  const state: CallState = {
    loading: false,
    complete: false,
    error: {
      message: error?.message,
    },
  };
  if (error instanceof Error) {
    state.error.stack = error.stack;
  }
  if (error instanceof HttpErrorResponse) {
    state.error.status = error.status;
    state.error.statusText = error.statusText;
  }
  return state;
};

export const initialCallState = (): CallState => ({
  loading: false,
  complete: false,
  error: undefined,
});

export const loadingCallState = (): CallState => ({
  loading: true,
  complete: false,
  error: undefined,
});
