import { ErrorHandler, Injector } from '@angular/core';
import { SnackbarService, SnackbarType } from '@miks-it-accounts/shared';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

class ProductionErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    const snackbar = this.injector.get(SnackbarService);
    const translate = this.injector.get(TranslateService);

    console.error(error);
    snackbar.show({
      message: translate.instant('genericErrorMessage'),
      type: SnackbarType.Error,
    });
  }
}

class DevelopmentErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    const snackbar = this.injector.get(SnackbarService);

    console.error(error);
    snackbar.show({
      // It's just a dev message, no translation needed
      message: 'An unhandled error ocurred - check the console for details',
      type: SnackbarType.Error,
    });
  }
}

export const errorHandlerProvider = environment.production
  ? {
      provide: ErrorHandler,
      useClass: ProductionErrorHandler,
      // `Store` depends on ErrorHandler so we can't inject it directly
      deps: [Injector],
    }
  : {
      provide: ErrorHandler,
      useClass: DevelopmentErrorHandler,
      // `Store` depends on ErrorHandler so we can't inject it directly
      deps: [Injector],
    };
