import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogComponent } from './dialog/dialog.component';

@NgModule({
  imports: [TranslateModule, CommonModule, OverlayModule],
  declarations: [DialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogModule {}
