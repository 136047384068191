import { Injectable } from '@angular/core';
import {
  completeCallState,
  errorCallState,
  initialCallState,
  loadingCallState,
} from '@miks-it-accounts/models';
import {
  SubscriptionUser,
  SubscriptionUsersService as MembersAPI,
} from '@miks-it/bff-api-angular-sdk';
import { BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { GlobalConfig } from './global-config.service';

@Injectable({ providedIn: 'root' })
export class FamilyService {
  public members$ = new BehaviorSubject(null);
  public loading$ = new BehaviorSubject(initialCallState());
  public deleting$ = new BehaviorSubject(initialCallState());

  constructor(
    private auth: AuthService,
    private membersAPI: MembersAPI,
    private config: GlobalConfig
  ) {}

  public async loadMembers(): Promise<SubscriptionUser[]> {
    await this.configureApi();

    this.loading$.next(loadingCallState());

    return this.membersAPI
      .meSubscriptionUsersGet()
      .pipe(
        take(1),
        map((response) => {
          this.members$.next(response.users);
          this.loading$.next(completeCallState());
          return response.users;
        }),
        catchError((err) => {
          this.loading$.next(errorCallState(err));
          throw err;
        })
      )
      .toPromise();
  }

  public async deleteMember(id: string): Promise<void> {
    await this.configureApi();

    this.deleting$.next(loadingCallState());

    return this.membersAPI
      .meSubscriptionUsersIdDelete(id)
      .pipe(
        take(1),
        switchMap((_response) =>
          this.members$.pipe(
            take(1),
            map((members) => {
              this.members$.next(members.filter((member) => member.id !== id));
              this.deleting$.next(completeCallState());
            })
          )
        ),
        catchError((err) => {
          this.deleting$.next(errorCallState(err));
          throw err;
        })
      )
      .toPromise();
  }

  private async configureApi() {
    const { vaultToken, keystoreToken } = await this.auth.getTokens();

    const credentials = {
      keystoreAccessToken: keystoreToken,
      vaultAccessToken: vaultToken,
      meecoDelegationId: null,
      subscriptionKey: this.config.get('subscriptionKey'),
    };

    this.membersAPI.configuration.basePath = this.config.get('bffApiBasePath');
    this.membersAPI.configuration.credentials = credentials;
  }
}
