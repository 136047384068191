import { Injectable } from '@angular/core';
import {
  completeCallState,
  errorCallState,
  initialCallState,
  loadingCallState,
} from '@miks-it-accounts/models';
import {
  SubscriptionInvitation,
  SubscriptionInvitationsService as InvitationsAPI,
} from '@miks-it/bff-api-angular-sdk';
import { BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { GlobalConfig } from './global-config.service';

@Injectable({ providedIn: 'root' })
export class InvitationsService {
  // Invitations data
  public invitations$ = new BehaviorSubject(null);
  public loading$ = new BehaviorSubject(initialCallState());
  public creating$ = new BehaviorSubject(initialCallState());
  public deleting$ = new BehaviorSubject(initialCallState());
  public accepting$ = new BehaviorSubject(initialCallState());

  constructor(
    private auth: AuthService,
    private invitationsAPI: InvitationsAPI,
    private config: GlobalConfig
  ) {}

  public async loadInvitations(): Promise<SubscriptionInvitation[]> {
    await this.configureApi();

    this.loading$.next(loadingCallState());

    return this.invitationsAPI
      .meSubscriptionInvitationsGet()
      .pipe(
        take(1),
        map((response) => {
          this.invitations$.next(response.invitations);
          this.loading$.next(completeCallState());
          return response.invitations;
        }),
        catchError((err) => {
          this.loading$.next(errorCallState(err));
          throw err;
        })
      )
      .toPromise();
  }

  public async createInvitation(
    description: string
  ): Promise<SubscriptionInvitation> {
    await this.configureApi();

    this.creating$.next(loadingCallState());

    return this.invitationsAPI
      .meSubscriptionInvitationsPost({ invitation: { description } })
      .pipe(
        take(1),
        switchMap((response) =>
          this.invitations$.pipe(
            take(1),
            map((invitations) => {
              this.invitations$.next([response.invitation, ...invitations]);
              this.creating$.next(completeCallState());
              return response.invitation;
            })
          )
        ),
        catchError((err) => {
          this.creating$.next(errorCallState(err));
          throw err;
        })
      )
      .toPromise();
  }

  public async deleteInvitation(id: string): Promise<void> {
    await this.configureApi();

    this.deleting$.next(loadingCallState());

    return this.invitationsAPI
      .meSubscriptionInvitationsIdDelete(id)
      .pipe(
        take(1),
        switchMap((_response) =>
          this.invitations$.pipe(
            take(1),
            map((invitations) => {
              this.invitations$.next(
                invitations.filter((invitation) => invitation.id !== id)
              );
              this.deleting$.next(completeCallState());
            })
          )
        ),
        catchError((err) => {
          this.deleting$.next(errorCallState(err));
          throw err;
        })
      )
      .toPromise();
  }

  public async acceptInvitation(token: string): Promise<void> {
    await this.configureApi();

    this.accepting$.next(initialCallState());

    return this.invitationsAPI
      .meSubscriptionInvitationsTokenAcceptPost(token)
      .pipe(
        take(1),
        map((_response) => {
          this.accepting$.next(completeCallState());
        }),
        catchError((err) => {
          this.accepting$.next(errorCallState(err));
          throw err;
        })
      )
      .toPromise();
  }

  private async configureApi() {
    const { vaultToken, keystoreToken } = await this.auth.getTokens();

    const credentials = {
      keystoreAccessToken: keystoreToken,
      vaultAccessToken: vaultToken,
      meecoDelegationId: null,
      subscriptionKey: this.config.get('subscriptionKey'),
    };

    this.invitationsAPI.configuration.basePath = this.config.get(
      'bffApiBasePath'
    );
    this.invitationsAPI.configuration.credentials = credentials;
  }
}
