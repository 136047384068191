import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppLoaderService } from '../app-loader.service';

@Component({
  selector: 'mks-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss'],
})
export class AppLoaderComponent {
  public isHidden$: Observable<boolean>;

  constructor(private appLoader: AppLoaderService) {
    this.isHidden$ = this.appLoader.enabled$.pipe(map((enabled) => !enabled));
  }
}
