import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  msalBrowserClientApplicationFactory,
  MSAL_BROWSER_TOKEN,
} from '@miks-it-accounts/services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { errorHandlerProvider } from './error-handler/error-handler';
import { GlobalConfig } from './services/global-config.service';
import { AppLoaderModule, SnackbarModule } from './shared';
import { AppTranslationModule } from './translation/app-translation.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppTranslationModule,
    BrowserModule,
    AppRoutingModule,
    SnackbarModule,
    AppLoaderModule,
  ],
  providers: [
    errorHandlerProvider,
    GlobalConfig,
    {
      provide: MSAL_BROWSER_TOKEN,
      useFactory: msalBrowserClientApplicationFactory,
      deps: [GlobalConfig],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
