import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppErrorComponent } from './app-error/app-error.component';
import { AppLoaderService } from './app-loader.service';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LoaderComponent, AppLoaderComponent, AppErrorComponent],
  bootstrap: [AppLoaderComponent, AppErrorComponent],
  exports: [LoaderComponent, AppLoaderComponent, AppErrorComponent],
  providers: [AppLoaderService],
})
export class AppLoaderModule {}
