import { InjectionToken } from '@angular/core';
import { PublicClientApplication } from '@azure/msal-browser';
import { GlobalConfig } from './global-config.service';

export const MSAL_BROWSER_TOKEN = new InjectionToken('MSALBrowser');

export function msalBrowserClientApplicationFactory(config: GlobalConfig) {
  return new PublicClientApplication({
    auth: {
      clientId: config.get('msalConfig').clientId,
      authority: config.get('msalConfig').authority,
      knownAuthorities: config.get('msalConfig').knownAuthorities,
      redirectUri: window.location.href.replace('/login', ''),
      postLogoutRedirectUri: window.location.href.replace('/login', ''),
    },
    cache: {
      cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
  });
}
