import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppLoaderModule } from './app-loader';
import { DialogModule } from './dialog/dialog.module';
import { ModalModule } from './modal/modal.module';
import { SnackbarModule } from './snackbar';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    DialogModule,
    SnackbarModule,
    AppLoaderModule,
  ],
  exports: [AppLoaderModule],
})
export class SharedModule {}
