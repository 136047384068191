<div class="notification py-3">
  <button
    class="button close-button is-ghost has-text-black"
    aria-label="close"
    (click)="close()"
  >
    <ion-icon name="close-outline"></ion-icon>
  </button>
  <div class="contification-content is-size-6">
    <div class="icon-container">
      <ion-icon
        *ngIf="type == TYPES.Success"
        class="success-icon is-size-4 is-flex"
        name="checkmark-circle"
      ></ion-icon>
      <ion-icon
        *ngIf="type == TYPES.Error"
        class="error-icon is-size-4 is-flex"
        name="close-circle"
      ></ion-icon>
    </div>
    <div class="message-container has-text-weight-medium">
      {{ message }}
    </div>
  </div>
</div>
