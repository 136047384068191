import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CANCELED, FREE_PLAN, NEVER_SET } from '@miks-it-accounts/models';
import { SubscriptionsService } from '@miks-it-accounts/services';
import { AppLoaderService } from '@miks-it-accounts/shared';
import { of, zip } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { LoggedInGuard } from './logged-in.guard';

@Injectable({ providedIn: 'root' })
export class HasNoSubscriptionOrCanceledGuard implements CanActivate {
  constructor(
    private router: Router,
    private subscriptions: SubscriptionsService,
    private loggedInGuard: LoggedInGuard,
    private appLoader: AppLoaderService
  ) {}

  /**
   * Guard to check if user is logged in and has no subscription or subscription is canceled
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.loggedInGuard.canActivate(next, state).pipe(
      switchMap((loggedIn) => {
        if (loggedIn === true) {
          this.subscriptions.loadUserSubscription().catch((err) => {
            this.appLoader.showError();
          });
          this.subscriptions.loadAvailablePlans().catch((err) => {
            this.appLoader.showError();
          });

          return zip(
            this.onceSubscriptionLoaded(),
            this.oncePlansLoaded()
          ).pipe(
            map(([subscription, _plans]) => {
              if (
                subscription &&
                subscription.status !== NEVER_SET &&
                subscription.status !== CANCELED &&
                subscription.active_product_id !== FREE_PLAN
              ) {
                return this.router.createUrlTree(['/']);
              }

              return true;
            })
          );
        }

        return of(loggedIn);
      })
    );
  }

  private onceSubscriptionLoaded() {
    return this.subscriptions.userSubscripionLoading$.pipe(
      filter(({ complete, error }) => complete || !!error),
      take(1),
      switchMap(() => this.subscriptions.userSubscription$.pipe(take(1)))
    );
  }

  private oncePlansLoaded() {
    return this.subscriptions.availablePlansLoading$.pipe(
      filter(({ complete, error }) => complete || !!error),
      take(1),
      switchMap(() => this.subscriptions.availablePlans$.pipe(take(1)))
    );
  }
}
