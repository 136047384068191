<nav
  *ngIf="isLoggedIn$ | async"
  class="navbar"
  [class.is-fixed-top]="isMobileNavOpen"
>
  <div class="container is-max-desktop">
    <div class="navbar-brand">
      <a class="navbar-item ml-4" routerLink="/">
        <img src="assets/images/logo.png" alg="Logo" />
      </a>
      <span
        class="navbar-burger burger"
        (click)="toggleMobileNav()"
        [class.is-active]="isMobileNavOpen"
      >
        <span></span>
        <span></span>
        <span></span>
      </span>
    </div>
    <div class="navbar-menu pr-4" [class.is-active]="isMobileNavOpen">
      <div class="navbar-end">
        <a
          class="navbar-item is-size-5-mobile has-text-weight-medium"
          (click)="closeNavBar()"
          routerLink="/"
        >
          {{ 'yourSubscription' | translate }}
        </a>
        <a
          href="https://miks-it.freshdesk.com"
          target="_blank"
          class="navbar-item is-size-5-mobile has-text-weight-medium"
        >
          {{ 'help' | translate }}
        </a>
        <a
          class="navbar-item is-size-5-mobile has-text-weight-medium"
          (click)="logout()"
        >
          {{ 'signOut' | translate }}
        </a>
      </div>
    </div>
  </div>
</nav>

<div class="container is-max-desktop">
  <router-outlet></router-outlet>
  <mks-app-loader></mks-app-loader>
  <mks-app-error
    [buttonText]="'retry' | translate"
    [messageText]="'bootstrapErrorMessage' | translate"
  ></mks-app-error>
</div>
