import { Injectable } from '@angular/core';
import {
  completeCallState,
  errorCallState,
  initialCallState,
} from '@miks-it-accounts/models';
import { GlobalConfig } from './global-config.service';

const STRIPE_SRC = 'https://js.stripe.com/v3';

@Injectable({ providedIn: 'root' })
export class StripeService {
  private status = initialCallState();

  public instance: stripe.Stripe;

  constructor(private config: GlobalConfig) {
    this.load();
  }

  private async load() {
    this.status.loading = true;

    this.status = await new Promise((resolve, _reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = STRIPE_SRC;

      script.onload = () => {
        this.instance = window.Stripe(this.config.get('stripePublishableKey'));
        resolve(completeCallState());
      };

      script.onerror = () =>
        resolve(errorCallState(new Error(`Failed to load ${STRIPE_SRC}`)));

      document.getElementsByTagName('head')[0].append(script);
    });
  }
}
