import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalConfig {
  get(name: string) {
    if (!window.CONFIG || !(name in window.CONFIG)) {
      throw Error(`${name} CONFIG value is missing`);
    }

    return window.CONFIG[name];
  }
}
