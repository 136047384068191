<div
  class="app-error hero has-text-centered is-fullwidth is-fullheight"
  [class.is-hidden]="isHidden$ | async"
>
  <div class="hero-body">
    <div class="column my-6">
      <img class="logo" src="assets/images/purple-searching.png" />
      <p class="p-4 has-text-weight-medium">
        {{ messageText }}
      </p>
      <button
        class="button mt-5 is-rounded retry-button is-primary"
        (click)="retry()"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>
