/**
 * Miksit
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface Event { 
    /**
     * ID of an event
     */
    id: string;
    /**
     * ID of the vault user
     */
    user_id: string;
    /**
     * The type of the object `subject_id` points to. Currently only `kid_invitation` is supported. 
     */
    subject_type?: Event.SubjectTypeEnum;
    /**
     * ID of the object/record that this event is about. Currently only `kid_invitation` is supported. 
     */
    subject_id: string;
    action?: Event.ActionEnum;
    /**
     * Can be one of the following 3 types:  * `requires_action` - a notification which requires a user\'s action * `action_taken` - a notification which has already been acted upon * `requires_no_action` - a regular event which requires no user\'s action 
     */
    actionable?: Event.ActionableEnum;
    inserted_at: string;
    /**
     * An event is often related to another user. In this case this field contains the ID of this user. 
     */
    other_user_id: string;
    /**
     * A set of data specific to a certain `subject_type`. The idea is to put as enough relevant information into this JSON object to avoid having to run additional HTTP queries to render this event or notification.  For `subject_type` `kid_invitation` the following data is present in `payload`:  * avatar_id * avatar_thumbnail_id * first_name * last_name * role 
     */
    payload: object;
}
export namespace Event {
    export type SubjectTypeEnum = 'kid_invitation';
    export const SubjectTypeEnum = {
        KidInvitation: 'kid_invitation' as SubjectTypeEnum
    };
    export type ActionEnum = 'incoming' | 'authored' | 'incoming_accepted' | 'incoming_rejected' | 'yours_accepted' | 'yours_rejected';
    export const ActionEnum = {
        Incoming: 'incoming' as ActionEnum,
        Authored: 'authored' as ActionEnum,
        IncomingAccepted: 'incoming_accepted' as ActionEnum,
        IncomingRejected: 'incoming_rejected' as ActionEnum,
        YoursAccepted: 'yours_accepted' as ActionEnum,
        YoursRejected: 'yours_rejected' as ActionEnum
    };
    export type ActionableEnum = 'requires_action' | 'action_taken' | 'requires_no_action';
    export const ActionableEnum = {
        RequiresAction: 'requires_action' as ActionableEnum,
        ActionTaken: 'action_taken' as ActionableEnum,
        RequiresNoAction: 'requires_no_action' as ActionableEnum
    };
}

