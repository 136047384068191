/**
 * Configuration that is shared across all environments
 */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBaseConfig {}

/**
 * Shared configuration used for all environments
 */
export const defaultBaseConfig: IBaseConfig = {};
