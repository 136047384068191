import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@miks-it-accounts/services';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.loading$.pipe(
      filter(({ complete, error }) => complete || !!error),
      switchMap(() =>
        this.auth.authData$.pipe(
          take(1),
          map((data) => {
            /**
             * MSAL after redirect drops user back to /login route.
             * To avoid user being redirected back to login this "if" solves the problem.
             */
            if (state.url.startsWith('/login')) {
              if (data) {
                return this.router.createUrlTree(['/']);
              }
              return true;
            }

            if (!data) {
              return this.router.createUrlTree(['/', 'login']);
            }
            return true;
          })
        )
      )
    );
  }
}
