import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  public enabled$ = new BehaviorSubject<boolean>(false);
  public error$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  public enable() {
    this.enabled$.next(true);
  }

  public disable() {
    this.enabled$.next(false);
  }

  public showError() {
    this.disable();
    this.error$.next(true);
  }

  public hideError() {
    this.error$.next(false);
  }
}
