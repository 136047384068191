/**
 * Miksit
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface MediaAttachment { 
    id: string;
    thumbnail_id: string;
    encrypted_dek: string;
    slot_id: string;
    attachments_folder_id: string;
    slot_type: MediaAttachment.SlotTypeEnum;
    /**
     * content_type that was assigned to the attachment during its creation
     */
    content_type: string;
    /**
     * filename that was assigned to the attachment during its creation
     */
    filename: string;
}
export namespace MediaAttachment {
    export type SlotTypeEnum = 'attachment' | 'attachments_folder';
    export const SlotTypeEnum = {
        Attachment: 'attachment' as SlotTypeEnum,
        AttachmentsFolder: 'attachments_folder' as SlotTypeEnum
    };
}

