import { InjectionToken } from '@angular/core';
// import { version } from 'src/environments/version';

export const URL_TOKEN = new InjectionToken<typeof URL>('url', {
  providedIn: 'root',
  factory: () => URL,
});

export const WINDOW_TOKEN = new InjectionToken<typeof window>('window', {
  providedIn: 'root',
  factory: () => window,
});

// export const VERSION_TOKEN = new InjectionToken<{
//   version: string;
//   revision: string;
//   branch: string;
// }>('version', {
//   providedIn: 'root',
//   factory: () => version,
// });
