import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogModule } from './dialog.module';
import { DialogComponent, DialogResult } from './dialog/dialog.component';

@Injectable({
  providedIn: DialogModule,
})
export class DialogService {
  private config = new OverlayConfig({});
  private overlayRef: OverlayRef;
  private portal: ComponentPortal<any>;
  private componentRef: ComponentRef<any>;
  private onDismiss$ = new Subject<DialogResult>();

  constructor(private overlay: Overlay) {}

  public async open(title: string, message: string) {
    if (this.overlayRef) {
      throw new Error('Dialog is already open');
    }

    this.overlayRef = this.overlay.create(this.config);
    this.portal = new ComponentPortal(DialogComponent);
    this.componentRef = this.overlayRef.attach(this.portal);

    const componentInstance = this.componentRef.instance;

    componentInstance.title = title;
    componentInstance.message = message;
    componentInstance.close = this.close.bind(this);

    const result = await this.onDismiss$.pipe(take(1)).toPromise();

    return result;
  }

  private close(result: DialogResult) {
    if (this.overlayRef) {
      this.onDismiss$.next(result);

      this.overlayRef.dispose();
      this.overlayRef = undefined;
      this.componentRef = undefined;
    }
  }
}
